import React from 'react';
import { connect } from 'react-redux';
import {
  getAuthorizationToken,
  removeAuthorizationToken,
  setAuthorizationToken,
} from 'services/authorization_token';
import { getCurrentUser } from 'models/current_user/requests';
import Loader from 'components/ui/loader';
import { removeCurrentUser } from 'models/current_user/store_actions';
import { getUrlSearchParam } from 'services/url';
import { setInsurgateCookie } from 'services/cookies';

class CheckForSession extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    const { getSession, setNoUser } = this.props;

    const tokenFromParams = getUrlSearchParam('token');

    if (tokenFromParams) {
      setAuthorizationToken(tokenFromParams);
    }

    const handleNoUser = () => {
      setInsurgateCookie('redirect_after_sign_in', window.location.href);
      setNoUser();
    };

    const token = getAuthorizationToken();
    if (token && token !== 'undefined') {
      getSession()
        .then(() => {
          this.setState({
            loading: false,
          });
        })
        .catch(() => {
          removeAuthorizationToken();
          handleNoUser();
          this.setState({
            loading: false,
          });
        });
    } else {
      this.setState({
        loading: false,
      });
      handleNoUser();
    }
  }

  render() {
    const { children } = this.props;
    const { loading } = this.state;
    if (loading) {
      return <Loader middleOfPage />;
    } else {
      return children;
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSession: () => dispatch(getCurrentUser()),
    setNoUser: () => dispatch(removeCurrentUser()),
  };
};

export default connect(null, mapDispatchToProps)(CheckForSession);
