import Cookies from 'js-cookie';
import {
  DISTRIBUTOR_DOMAIN,
  FOR_DISTRIBUTOR,
  INSURGATE_DOMAIN,
  IS_NETLIFY,
} from 'constants/application_constants';

const cookieDomain = FOR_DISTRIBUTOR
  ? DISTRIBUTOR_DOMAIN
  : `.${INSURGATE_DOMAIN}`;

export const getCookie = (name) => Cookies.get(name);

export const setCookie = (name, value) => {
  return Cookies.set(name, value, IS_NETLIFY ? null : { domain: cookieDomain });
};

export const setInsurgateCookie = (name, value) => {
  return Cookies.set(name, value, { domain: `.${INSURGATE_DOMAIN}` });
};

export const removeCookie = (name) => {
  Cookies.remove(name, IS_NETLIFY ? null : { domain: cookieDomain });
  Cookies.remove(name, { domain: '.openbroker.be' });
};
